/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

.price-cards-container{
    display: flex; 
    justify-content: center; 
    align-items: center; 
    width: 95%;
    gap: 24px;

    @media (max-width: 1450px){
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 950px){
        display: flex;
        flex-direction: column;
    }
}

.price-card{
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: column;
}

.we-do-items-container{
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 24px;
    row-gap: 24px;
    margin: auto;

    @media (max-width: 1600px){
        grid-template-columns: auto auto;
    }
    @media (max-width: 1100px){
        grid-template-columns: auto;
    }
}

.we-do-item{
    display: flex;
    gap: 8px;
    width: 330px;
    justify-content: center;
    margin: auto;

    @media (max-width: 1100px){
        width: 410px;
    }
}