/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/


.schedule-week-button {
    display: block;
    background-color: #EF3C39;
    border-radius: 4px;
    border: none;
    width: 30px;
    height: 30px;
    margin: 4px;
}
.schedule-week-button:hover {
    background-color: #c4312e;
    cursor: pointer;
}


.schedule-driver-button:hover {
    opacity: 100;
    cursor: pointer;
}

.schedule-driver-button {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 48px;
    background-color: rgba(0, 0, 0, 0.1);
    color: var(--bs-primary);
    
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 250ms linear;
}

.schedule-match-route-button {
    position: relative;
    background-color: white;
    border-radius: 6px;
    width: 100%;
}

.route-creator-selectable-row:hover {
    cursor: pointer;
}


.schedule-match-click-to-view {
    position: relative;
}
.schedule-match-click-to-view:hover .schedule-match-click-to-view-text {
    opacity: 1;
    
}

.schedule-match-click-to-view:hover > p {
    filter: blur(3px);
}


.schedule-match-click-to-view-text {
    cursor: pointer;
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    text-align: center;
    opacity: 0;
    font-size: 14px;
    font-weight: normal;
}

