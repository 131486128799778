/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

@import url('https://fonts.googleapis.com/css?family=Gothic+A1');

.App {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  background-color: rgb(240, 240, 240);
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}



#content-wrapper {
  background-color: antiquewhite;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.cwt-in {
  transition: margin-left 230ms ease-in;
}

.cwt-out {
  transition: margin-left 250ms ease-in;
}

.my-node-enter {
  transform: translateX(-250px);
  margin-right: -250px;
}
.my-node-enter-active {
  transform: translateX(0px);
  margin-right: 0px;
  transition: transform 250ms, margin-right 250ms;
}
.my-node-exit {
  margin-right: 0px;
  transform: translateX(0px);
}
.my-node-exit-active {
  transform: translateX(-250px);
  margin-right: -250px;
  transition: transform 250ms, margin-right 250ms;
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
